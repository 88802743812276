import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Globals, ProductItem, AddressData } from '../../../globals'
import { DataService } from '../../../data.service';

declare interface CartData {
    items: ProductItem[];
}

declare var Stripe: any;

@Component({
  selector: 'app-orderConfirmation',
  templateUrl: './orderConfirmation.component.html',
  styleUrls: ['./orderConfirmation.component.scss'],
})

export class OrderConfirmationComponent implements OnInit {
    orderConfirmationNumber: number = 1234567890;
    orderTotal: number = 12345.67;
    orderDate: any;
    cartData: CartData;
    billingAddress: AddressData = new AddressData();
    shippingAddress: AddressData = new AddressData();
    taxes: number = 0;
    shippingCost: number = 5;
    itemCartCount: number = 0;

    constructor(public globals: Globals, private modalService: NgbModal,
        private dataService: DataService) {
        this.handleSubmit = this.handleSubmit.bind(this);
        //set cart data
        this.cartData = this.dataService.cartData; //this.getCartJSON();
        this.shippingAddress = Object.assign({}, this.dataService.shippingAddress);
        this.orderConfirmationNumber = this.dataService.orderNumber;
        this.orderDate = this.dataService.orderDate;
    }

    ngOnInit() {
    }

    getCartItemCount(){
        let cartItemCount = 0;
        let cartDataString = JSON.stringify(this.dataService.cartData);
        if(cartDataString != null){
            let cartData = JSON.parse(cartDataString);
            cartData.forEach(item => {
                cartItemCount += item.count;
            });
        }
        return cartItemCount;
    }

    handleSubmit(e){
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }
    
    getTotal(){
        let total = 0;
        total += this.getSubTotal() + this.taxes + this.shippingCost;
        return total;
    }

    getSubTotal(){
        let subTotal = 0;
        let cartDataString = JSON.stringify(this.dataService.cartData);
        if(cartDataString != null){
            let cartData = JSON.parse(cartDataString);
            cartData.forEach(item => {
                subTotal += Number(item.price)*item.count;
            });
        }
        return subTotal;
    }
}
