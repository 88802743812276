import { Component, OnInit, Inject } from '@angular/core';
import { HttpHelperService } from '../../httphelper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductItem } from '../../globals'
import { DOCUMENT } from '@angular/common';
import { EventEmitterService } from '../../event-emitter.service';    

@Component({
  selector: 'app-productpage',
  templateUrl: './productpage.component.html',
  styleUrls: ['./productpage.component.scss']
})

export class ProductPageComponent implements OnInit {
    data : Date = new Date();
    selectedProductItem: ProductItem;

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    dropdownList1 = [];
    selectedItems1 = [];
    dropdownSettings1 = {};
    

    constructor(private httpHelper: HttpHelperService,  @Inject(DOCUMENT) private document: Document, private eventEmitter: EventEmitterService) { 
        let urlParts = this.document.location.href.split("/");
        let selectedIndex = urlParts[urlParts.length-1];

        this.httpHelper.getProductList2().subscribe(res => {
            let productList = <ProductItem[]>res;// ProductItem[] = JSON.parse(JSON.stringify(res));
            console.log(JSON.stringify(productList));
            productList.forEach(item => {
                if(item.productid === selectedIndex){
                    this.selectedProductItem = item;
                }
            });
            (err: HttpErrorResponse) => {
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
            }
        });
    }

    
    addToCart(){
        this.eventEmitter.onShoppingCartAddToCart(this.selectedProductItem);
    }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        // body.classList.add('buy-product');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        // navbar.classList.add('bg-danger');

        this.dropdownList = [
                              {"id":1,"itemName":"Black"},
                              {"id":2,"itemName":"Gray"},
                              {"id":3,"itemName":"White"}
                            ];
        this.selectedItems = [
            {"id":1,"itemName":"Black"}
        ];
        this.dropdownSettings = {
                                  singleSelection: true,
                                  text:"Select language",
                                  selectAllText:'Select All',
                                  unSelectAllText:'UnSelect All',
                                  enableSearchFilter: true,
                                  classes:""
                                };
        this.dropdownList1 = [
                              {"id":1,"itemName":"Small"},
                              {"id":2,"itemName":"Medium"},
                              {"id":3,"itemName":"Large"}
                            ];
        this.selectedItems1 = [
            {"id":1,"itemName":"Small"},
        ];
        this.dropdownSettings1 = {
                                  singleSelection: true,
                                  text:"Select currency",
                                  selectAllText:'Select All',
                                  unSelectAllText:'UnSelect All',
                                  enableSearchFilter: true,
                                  classes:""
                                };
    }
    onItemSelect(item:any){
        console.log(item);
        console.log(this.selectedItems);
    }
    OnItemDeSelect(item:any){
        console.log(item);
        console.log(this.selectedItems);
    }
    onSelectAll(items: any){
        console.log(items);
    }
    onDeSelectAll(items: any){
        console.log(items);
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('buy-product');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        navbar.classList.remove('bg-danger');
    }
}
