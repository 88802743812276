import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRegisterComponent } from './login-register.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from '../profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { NotificationComponent } from '../notification/notification.component';

import { ShoppingCartModule } from '../shopping-cart/shopping-cart.module'
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ShoppingCartModule,
    NgbModule
  ],
  declarations: [
    LoginRegisterComponent,
    ProfileComponent
  	//NotificationComponent
  ],
  exports: [LoginRegisterComponent ]
})
export class LoginRegisterModule { }
