import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { Globals } from './globals';
import { ConditionalExpr } from '@angular/compiler';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})

export class HttpHelperService {
  constructor(private http: HttpClient, private globals: Globals, private cookieService: CookieService) { 
  }

  private config = {
      headers : {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
      }
  }

  checkout(payload){
    var config = this.getHeaderWithUserToken();
    return this.http.post("https://shop.devopsdesigns.com/api/checkout", payload, this.config);
  }

  getHeaderWithUserToken(){
    var userToken = localStorage.getItem("userToken");
    var config = {
        headers : {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8;",
          "authorizationToken": userToken
        }
    }
    return config;
  }

  getCartList(){
    var config = this.getHeaderWithUserToken();
    return this.http.get("https://shop.devopsdesigns.com/Prod" + "/api", config);
  }

  getProductList2(){
    // console.log("getProductList2");
    var config = this.getHeaderWithUserToken();
    // console.log(config);
    return this.http.get("https://shop.devopsdesigns.com/api/products", this.config);
  }

  verifyToken(){
    var config = this.getHeaderWithUserToken();
    return this.http.post("https://shop.devopsdesigns.com/api/token", null, config).pipe(
      map((res: any) => {
            //console.log(res);
        },
        (err: HttpErrorResponse) => {
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    ));
  }

  login(username, password) {
    var payload = {username: username, password: password, action: this.globals.serviceBaseActionLogin};

    return this.http.post(this.globals.serviceBaseUrl + "/login", payload, this.config).pipe(
      map((res: any) => {
            console.log(res);
            JSON.parse(JSON.stringify(res), (key, value) => {
              if(key === "token"){
                console.log("token: " + value);
                localStorage.setItem("userToken", value);
                this.cookieService.set('authToken',  value);
              }
            });
        },
        (err: HttpErrorResponse) => {
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
        }
    ));

  }
  
  signup(username, password) {
    var payload = {username: username, password: password, action: this.globals.serviceBaseActionCreate};
    console.log(payload);

    return this.http.post(this.globals.serviceBaseUrl + "/login", payload, this.config).pipe(
      map((res: any) => {
            console.log(res);
            JSON.parse(JSON.stringify(res), (key, value) => {
              if(key === "token"){
                console.log("token: " + value);
                localStorage.setItem("userToken", value);
                this.cookieService.set('authToken',  value);
              }
            });
        },
        (err: HttpErrorResponse) => {
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            if(err.status === 403){
              console.log("User account already exist");
            }
        }
    ));
  }

  getProductList(){ 
    // console.log("getProductList");
    var config = this.getHeaderWithUserToken();
    // console.log(config);
    return this.http.post(this.globals.serviceBaseUrl + "/api", null, config);
  }
}
