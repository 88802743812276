import { Component, OnInit, Input } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Globals, ProductItem, ServerCartData } from '../../globals'
import { IAlert } from '../../shared/notification/notification.component'
import { HttpHelperService } from '../../httphelper.service';
import { NgbTypeaheadWindow } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { EventEmitterService } from '../../event-emitter.service';    
import { ControlPosition } from '@agm/core';

declare interface TableData {
    headerRow: string[];
    dataRows: string[][];
}

declare interface CartData {
    items: ProductItem[];
}

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})

export class ShoppingCartComponent implements OnInit {
    @Input() isRound: boolean = false;
    @Input() image: string;
    @Input() fullPage: boolean = true;
    state: any = {}
    serverCartData: ServerCartData;
    discountcode: string = "";

    closeResult: string;
    uploadModal: any;
    emailAddress: string; 
    loginPassword: string;
    invalidLogin: boolean = false;
    registerFailed: boolean = false; 
    loggedIn: boolean = false;
    public tableData3: TableData = { headerRow: [ '#', 'Product Name', 'Type', 'Qty', 'Price', 'Amount'], dataRows: []};
    cartData: any;
    itemCartCount: number = 0;
    newAddedItem: ProductItem;

    constructor(
            private modalService: NgbModal, 
            public globals: Globals, 
            private httpHelper: HttpHelperService, 
            private http: HttpClient,
            private eventEmitterService: EventEmitterService,
            private router: Router
        ) {
        // this.httpHelper.getCartList().map((res: Response) => res.json()).subscribe(products: Object) => {
        // this.httpHelper.getCartList().subscribe(res => {
        //     let cartList = <CartData>res;// ProductItem[] = JSON.parse(JSON.stringify(res));
        //     for(let item of cartList.items){
        //         this.tableData3.dataRows.push([item.photos[0].image_url, item.name, item.description, item.price, '1', item.price]);
        //     }
        //     // var shoppingCartList = JSON.parse(JSON.stringify(res));
        //     if(this.tableData3.dataRows != null){
        //         this.itemCartCount = this.tableData3.dataRows.length;
        //     }
        //     (err: HttpErrorResponse) => {
        //         console.log(err.name);
        //         console.log(err.message);
        //         console.log(err.status);
        //     }
        // });
        this.serverCartData = {cart: [], action: "checkout", cart_id: "123456", codes: []}
        this.refreshCart();
    }

    addToCart(selectedItem: ProductItem){
        let cartData = [];
        selectedItem.count = 1;
        if(this.getCartString() == null){
            cartData.push(selectedItem);
        }else{
            cartData = this.getCartJSON();
            let productInCart = cartData.find(({ productid }) => productid === selectedItem.productid);
            if(productInCart != null){
                productInCart.count++;
            }
            else{
                cartData.push(selectedItem);
            }
        }
        localStorage.setItem('cart', JSON.stringify(cartData));
        this.refreshCart();

        this.newAddedItem = selectedItem;

        console.log(this.newAddedItem.name);

        //show pop up for newly added item
        document.getElementById("addToCartButton").click();
        
        //this.router.navigateByUrl('/cart');
        // let alert: IAlert;
        // alert = {id: 1, type: 'success', message: selectedItem.name + ' has been added to your cart'};
        // this.eventEmitterService.onShowNotification(alert);
    }

    viewCart(){
        this.router.navigateByUrl('/cart');
    }

    checkout(){
        this.router.navigateByUrl('/shop/checkout');
    }

    removeItemFromCart(item: ProductItem){

    }

    emptyCart(){
        localStorage.removeItem("cart");
        this.tableData3.dataRows = [];
        this.itemCartCount = this.tableData3.dataRows.length;
        var container = document.getElementById("cartCount");
        container.innerHTML= "0"; 
    }

    refreshCart(){
        let cartDataString = this.getCartString();
        if(cartDataString != null){
            this.cartData = JSON.parse(cartDataString);
            this.tableData3.dataRows = [];
            this.cartData.forEach(item => {
                console.log(item);
                this.tableData3.dataRows.push([item.photo, item.name, item.description, item.price, item.count, item.price, item.productid]);
            });
        }
        this.itemCartCount = this.getCartItemCount();
        var container = document.getElementById("cartCount");
        if(container != null){
            container.innerHTML= this.itemCartCount.toString(); 
        }
    }

    getCartItemCount(){
        let cartItemCount = 0;
        let cartDataString = this.getCartString();
        if(cartDataString != null){
            let cartData = JSON.parse(cartDataString);
            cartData.forEach(item => {
                cartItemCount += item.count;
            });
        }
        return cartItemCount;
    }

    open(content, type) {
        this.wipeData();
        this.uploadModal = this.modalService.open(content);
        this.uploadModal.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    login(){
        this.httpHelper.login(this.emailAddress, this.loginPassword).subscribe(
            res => {
                console.log(res);
                this.loggedIn = this.isLoggedIn();
                this.close();
            },
            (err: HttpErrorResponse) => {
                this.invalidLogin = true; //login failed
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
            }
        );
    }

    isLoggedIn(){
        this.httpHelper.verifyToken().subscribe( res => {
                this.loggedIn = true;
                console.log(res);
                return true;
            },(err: HttpErrorResponse) => {
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                return false;
            }
        );
        return false;
    }

    setLoggedIn(loggedIn: boolean){
        localStorage.setItem("loggedIn", loggedIn.toString());
    }

    register(){
        this.httpHelper.signup(this.emailAddress, this.loginPassword).subscribe(res => {
            this.wipeData();
            this.close();
        },
        (err: HttpErrorResponse) => {
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            if(err.status === 403){
                console.log("User account already exist");
                this.registerFailed = true;
            }
        });
    }

    wipeData(){
        this.emailAddress = "";
        this.loginPassword = "";
        this.registerFailed = false;
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.state = {
            file: null,
            imagePreviewUrl: this.image !== undefined ? this.image:(this.isRound ? './assets/img/placeholder.jpg':'./assets/img/image_placeholder.jpg')
        }
        if (this.eventEmitterService.subsVar==undefined) {    
            this.eventEmitterService.subsVar = this.eventEmitterService.    
            invokeAddToCartFunction.subscribe((product: ProductItem) => {    
              this.addToCart(product);    
            });    
            this.eventEmitterService.subsVar3 = this.eventEmitterService.    
            invokeClearCartFunction.subscribe(() => {    
              this.emptyCart();    
            });
          }            
    }

    handleSubmit(e){
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }

    handleRemove(){
        this.state.file = null;
        this.state.imagePreviewUrl = this.image !== undefined ? this.image:(this.isRound ? './assets/img/placeholder.jpg':'./assets/img/image_placeholder.jpg');

        var el = document.querySelector('img');
        var img = document.createElement('img');
      
        el.parentNode.replaceChild(img, el);
    }

    close(){
        this.uploadModal.close();
    }

    logout(){
        this.setLoggedIn(false);
        localStorage.clear();//("userToken", null);
        this.loggedIn = this.isLoggedIn();
    }

    recoverAccount(){
        this.close();
    }

    getTotal2() {
        var total = 0;
        if(this.tableData3.dataRows != null){
            for( var i = 0; i < this.tableData3.dataRows.length; i++ ){
                var price = parseFloat(this.tableData3.dataRows[i][5])
                var count = parseInt(this.tableData3.dataRows[i][4])
                total += count*price;
            }
        }
        return total;
    };

    getDelivery(){
        return 5;
    }

    getTaxesAndFees(){
        return this.getTotal2()*this.getTaxRate()
    }

    getTaxRate(){
        return 0.0875
    }

    showElements() {
        if(this.tableData3.dataRows != null){
            for( var i = 0; i < this.tableData3.dataRows.length; i++ ){
                console.log(this.tableData3.dataRows[i][1])
            }
        }

        let cartDataString = this.getCartString();
        if(cartDataString != null){
            let cartData = JSON.parse(cartDataString);
            cartData.forEach(item => {
                this.serverCartData.cart.push({product_id: item.productid, quantity: item.count});
            });
        }
        this.serverCartData.codes.push({discount_code: this.discountcode});
        console.log(this.serverCartData);
    
        this.httpHelper.checkout(this.serverCartData).subscribe(res => {
                console.log(res);
            (err) => {
                // console.log(err.name);
                // console.log(err.message);
                // console.log(err.status);
                console.log(err);
            }
        });

        this.router.navigateByUrl('/shop/checkout');
    }

    //subtract one from the item count
    removeItem(selectedId: number){
        if(this.tableData3.dataRows != null){
            for( var i = 0; i < this.tableData3.dataRows.length; i++ ){
                var productid = parseInt(this.tableData3.dataRows[i][6])
                if(selectedId == productid){
                    let cartData = this.getCartJSON();
                    cartData.splice(i,1);
                    this.storeCartData(cartData);
                    this.refreshCart();
                    return;
                }
            }
        }
    }

    //subtract one from the item count
    subtractOne(selectedId: number){
        if(this.tableData3.dataRows != null){
            for( var i = 0; i < this.tableData3.dataRows.length; i++ ){
                var productid = parseInt(this.tableData3.dataRows[i][6])
                if(selectedId == productid){
                    let cartData = this.getCartJSON();
                    let productInCart = cartData.find(({ productid }) => productid === selectedId);
                    if(productInCart.count==1){
                        cartData.splice(i,1);
                    }else{
                        productInCart.count--;
                    }
                    this.storeCartData(cartData);
                    this.refreshCart();
                    return;
                }
            }
        }
    }

    //add one to the item count
    addOne(selectedId: number){
        console.log(selectedId);
        if(this.tableData3.dataRows != null){
            for( var i = 0; i < this.tableData3.dataRows.length; i++ ){
                var productid = parseInt(this.tableData3.dataRows[i][6])
                if(selectedId == productid){
                    let cartData = this.getCartJSON();
                    let productInCart = cartData.find(({ productid }) => productid === selectedId);
                    if(productInCart != null){
                        productInCart.count++;
                        this.storeCartData(cartData);
                        this.refreshCart();
                        return;
                    }
                }
            }
        }
    }

    applyDiscount(){
        document.getElementById('discount-msg').textContent = "Invalid code. Please try again.";
    }

    storeCartData(cartData){
        localStorage.setItem('cart', JSON.stringify(cartData));
    }

    getCartString(){
        return localStorage.getItem('cart');
    }

    getCartJSON(){
        return JSON.parse(this.getCartString());
    }
}
