import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { NouisliderModule } from 'ng2-nouislider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AgmCoreModule } from '@agm/core';
import { ImageUploadModule } from '../shared/image-upload/image-upload.module';

import { EcommerceComponent } from './ecommerce.component';
import { ProductPageComponent } from './productpage/productpage.component';
import { ProductCardComponent } from './productcard/productcard.component';

import { RouterModule } from '@angular/router';
import { FooterModule } from '../shared/footer/footer.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    TagInputModule,
    NouisliderModule,
    JwBootstrapSwitchNg2Module,
    AngularMultiSelectModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBr-tgUtpm8cyjYVQDrjs8YpZH7zBNWPuY'
    }),
    CommonModule,
    RouterModule,
    ImageUploadModule,
    FooterModule
  ],
  declarations: [
    EcommerceComponent,
    ProductPageComponent,
    ProductCardComponent
  ],
  exports: [ EcommerceComponent ]
})
export class EcommerceModule { }
