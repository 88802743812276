import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AddressData, AccountInfo } from './globals';

@Injectable()
export class DataService {
    public shippingAddress: AddressData;
    public billingAddress: AddressData;
    public cartData: any;
    public orderNumber: number;
    public orderDate: any;
    public currentProfile: AccountInfo = new AccountInfo();

    // private messageSource = new BehaviorSubject('default message');
    // currentMessage = this.messageSource.asObservable();

    constructor() { }

    // setOrderData(shippingAddress: AddressData, cartData: any, orderNumber) {
    //     this.messageSource.next(message)
    // }

}