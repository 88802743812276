import { Injectable } from '@angular/core';

//“curl -s -X POST -d '{ "username":"fcarpio2", "password":"testpass", "action":"login"}' https://g6sgclcxol.execute-api.us-west-2.amazonaws.com/Prod/login”
//“curl -H 'authorizationToken: eyJ1c2VybmFtZSI6ImZjYXJwaW8yIiwiZXhwaXJlcyI6MTU4NzUxNjA0Nn0' -X POST https://es6hi5emt0.execute-api.us-west-2.amazonaws.com/dev/api/test”
// https://d2gkw8byc3jesd.cloudfront.net/login and https://d2gkw8byc3jesd.cloudfront.net/api

declare interface PhotoData {
  caption: string;
  image_url: string;
}

export interface ProductItem {
  description: string;
  name: string;
  price: string;
  photos: PhotoData[];
  productid: string;
  count: number;
}

export interface ServerCartData {
  cart: items[];
  codes: code[];
  action: string;
  cart_id: string;
}

declare interface items {
  product_id: string;
  quantity: number;
}

declare interface code {
  discount_code: string;
}

@Injectable()
export class Globals {
  siteTitle: string = 'DevOps Storefront';
  siteTagline: string = 'The store that has everything';
  loggedIn: boolean = true;
  private serviceBaseDev: string = 'https://shop.devopsdesigns.com'
  private serviceBaseProd: string = 'https://shop.devopsdesigns.com'
  serviceBaseUrl: string = this.serviceBaseDev
  serviceBaseActionLogin: string = 'login'
  serviceBaseActionCreate: string = 'create'
}

export class AddressData {
  name: string = '';
  streetAddress1: string = '';
  streetAddress2: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';

  public toString = () : string => {
      return 'Name: ' + this.name + '\nStreet: ' + this.streetAddress1 + '\nStreet2: ' + this.streetAddress2
      + '\nCity: ' + this.city + '\nState: ' + this.state + '\nZip: ' + this.zip;
  }

  public clear(){
      this.name  = '';
      this.streetAddress1 = '';
      this.streetAddress2 = '';
      this.city = '';
      this.state = '';
      this.zip = '';
  }
}

export class AccountInfo {
  name: string = "";
  email: string = "";
  billingAddress: AddressData = new AddressData();
  shippingAddress: AddressData = new AddressData();
  newsletterSubscribed: boolean = false;
}

// export interface AddressData {
//   name: string;
//   streetAddress1: string;
//   streetAddress2: string;
//   city: string;
//   state: string;
//   zip: string;

//   // public toString = () : string => {
//   //     return 'name: ' + this.name + '\nStreet: ' + this.streetAddress1 + '\nStreet2: ' + this.streetAddress2
//   //     + '\nCity: ' + this.city + '\nState: ' + this.state + '\nZip: ' + this.zip;
//   // }

//   // public clear(){
//   //     this.name  = '';
//   //     this.streetAddress1 = '';
//   //     this.streetAddress2 = '';
//   //     this.city = '';
//   //     this.state = '';
//   //     this.zip = '';
//   // }
// }

