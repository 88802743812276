import { Component, OnInit, Input, Inject } from '@angular/core';
import { Globals, ProductItem } from '../../globals'
import { EventEmitterService } from '../../event-emitter.service';    

@Component({
  selector: 'app-product-card',
  templateUrl: './productcard.component.html',
  styleUrls: ['./productcard.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() productList: ProductItem[];
  @Input() pageTitle: string;
  @Input() pageStyle: string;
  @Input() allowSorting: boolean = true;
  @Input() showAddToCartBtn: boolean = false;

  cardsID: string = "cards" + Math.floor(Math.random()*10000).toString();

  sortDropdownList = [];
  sortSelectedItems = [];
  sortDropdownSettings = {};

  constructor(private globals: Globals, private eventEmitter: EventEmitterService) { 
  }

  ngAfterViewInit(){
    var cards = document.getElementById(this.cardsID);
    cards.classList.add('section-' + this.pageStyle);
  }
  ngOnInit(){
    // alert(this.cardsID);
    // var cards = document.getElementById(this.cardsID);
    // cards.classList.add('section-' + this.pageStyle);

    this.sortDropdownList = [
      {"id":1,"itemName":"Best seller"},
      {"id":2,"itemName":"Price: low to high"},
      {"id":3,"itemName":"Price: high to low"},
      {"id":4,"itemName":"New"}
    ];
    this.sortSelectedItems = [
    ];
    this.sortSelectedItems.push(this.sortDropdownList[0]);
    this.sortDropdownSettings = {
      singleSelection: true,
      text:"Single Select",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: false,
      classes:""
    };
  }

  onItemSelect(item: any){
    if(item.id==2){
      this.productList.sort
    }
    console.log(item);
    console.log(this.sortSelectedItems);
  }
  OnItemDeSelect(item: any){
      console.log(item);
      console.log(this.sortSelectedItems);
  }
  onSelectAll(items: any){
      console.log(items);
  }
  onDeSelectAll(items: any){
      console.log(items);
  }

  ngOnDestroy(){
    // var body = document.getElementsByTagName('body')[0];
    // body.classList.remove('ecommerce');
    // var navbar = document.getElementsByTagName('nav')[0];
    // navbar.classList.remove('navbar-transparent');
  }

  addToCart(productData){
    this.eventEmitter.onShoppingCartAddToCart(productData);
  }

  

}
