import { Component, OnInit , Input} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { HttpHelperService } from '../httphelper.service';
import { Globals, ProductItem } from '../globals';

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})

export class EcommerceComponent implements OnInit {
    doubleSlider = [1000, 5000];
    @Input() fullPage: boolean = true;
    data : Date = new Date(); 
    
    productList: ProductItem[];

    constructor(private httpHelper: HttpHelperService){
        this.httpHelper.getProductList2().subscribe(res => {
            this.productList = <ProductItem[]>res;
            var shoppingCartList = JSON.parse(JSON.stringify(res));
            (err: HttpErrorResponse) => {
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
            }
        });
     }

    ngOnInit() {
        // var body = document.getElementsByTagName('body')[0];
        // body.classList.add('ecommerce');
        // var navbar = document.getElementsByTagName('nav')[0];
        // navbar.classList.add('navbar-transparent');

        
    }
    ngOnDestroy(){
        // var body = document.getElementsByTagName('body')[0];
        // body.classList.remove('ecommerce');
        // var navbar = document.getElementsByTagName('nav')[0];
        // navbar.classList.remove('navbar-transparent');
    }
}
