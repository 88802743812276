import { Component, OnInit } from '@angular/core';
import { Globals, AddressData } from '../../globals'
import { DataService } from '../../data.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    data: Date = new Date();
    sameShippingAndBilling: boolean = true;
    billingAddress: AddressData = new AddressData();
    shippingAddress: AddressData = new AddressData();
    subscribedToNewletter: boolean = false; 

    constructor(private staticData: DataService){
        this.subscribedToNewletter = staticData.currentProfile.newsletterSubscribed;
        console.log("newsletter value (local) " + this.subscribedToNewletter);
        console.log("newsletter value (static) " + this.staticData.currentProfile.newsletterSubscribed);

     }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        // navbar.classList.add('bg-danger');
    }

    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        // navbar.classList.remove('');
    }

    newsletterCheckboxChange(){
        console.log("newsletter checkbox value has changed to " + this.subscribedToNewletter);
        // console.log("newsletter checkbox value has changed to " + this.staticData.currentProfile.newsletterSubscribed);
    }

}
