import { Component, OnInit, Input } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// import { Http, Response } from '@angular/http';

import { Globals } from '../../globals'

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})

export class ImageUploadComponent implements OnInit {
    @Input() isRound: boolean = false;
    @Input() image: string;
    state: any = {}

    closeResult: string;
    uploadModal: any;
    emailAddress: string;

    constructor(private modalService: NgbModal, private http: HttpClient, public globals: Globals) {
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    open(content, type) {
        if (type === 'sm') {
            console.log('aici');
            this.transcodeFile();
            this.uploadModal = this.modalService.open(content, { size: 'sm', backdrop: 'static' });
            this.uploadModal.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            this.uploadModal = this.modalService.open(content);
            this.uploadModal.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.state = {
            file: null,
            imagePreviewUrl: this.image !== undefined ? this.image:(this.isRound ? './assets/img/placeholder.jpg':'./assets/img/image_placeholder.jpg')
        }
    }

    handleImageChange(e){
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        this.state.file = file;    

        /*
        reader.onloadend = () => {
            this.state.file = file;    
            this.state.imagePreviewUrl = reader.result;
        }
        reader.readAsDataURL(file);
        */

        reader.onload = function() {
        var blob = new Blob([reader.result], {type: file.type});
        var url = URL.createObjectURL(blob);
        var video = document.createElement('video');
        
        var timeupdate = function() {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
        };
        
        video.addEventListener('loadeddata', function() {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
        });
        
        var snapImage = function() {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              var el = document.querySelector('img');
              //var el = document.getElementById('video-thumbnail');
              var img = document.createElement('img');
              img.src = image;
              
              el.parentNode.replaceChild(img, el);

              URL.revokeObjectURL(url);
            }
            return success;
        };

        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.autoplay = true;
        video.play();
        };
        reader.readAsArrayBuffer(file);
    }

    handleSubmit(e){
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }
    
    handleClick(){
        var input = document.createElement("input");
        input.type = "file";
        input.accept = "video/mp4,video/x-m4v,video/*";
        //input.onchange = this.handleImageChange;
        
        input.addEventListener("change", this.handleImageChange);
        input.style.display = "none";
        
        document.body.appendChild(input);
        input.click();
    }
    transcodeFile(){
        this.uploadModal.close();

        const bucket = new S3(
          {
            accessKeyId: 'AKIA34ZOG5NDGA6IVO7Y',
            secretAccessKey: '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',
            region: 'us-west-2'
          }
        );
         
        const params = {
          Bucket: 'dd-temp',
          Key: 'web-transcoder/' + this.emailAddress.toLowerCase() + '/' + this.state.file.name,
          Body: this.state.file
        };
         
        var parent = this;
        bucket.upload(params, function (err, data) {
          //console.log(err);
          if(err == null){
            //successful upload
            //String.Format(parent.globals.transcodeFileUrl, parent.emailAddress, parent.state.file.name);
            var transcodeUrl = "https://transcoder.devopsdesigns.com/api?email="+parent.emailAddress+"&filename="+parent.state.file.name;
            console.log(transcodeUrl);
            parent.http.get(transcodeUrl).subscribe(result => {
                console.log("successfully called transcoding api");
              }, error => {
                console.error(error);
              });
            parent.uploadModal.close();
            parent.handleRemove();
            parent.emailAddress = '';
          }else{
            //failed upload
          }
          //console.log(data);
        });
        
    }

    handleRemove(){
        this.state.file = null;
        this.state.imagePreviewUrl = this.image !== undefined ? this.image:(this.isRound ? './assets/img/placeholder.jpg':'./assets/img/image_placeholder.jpg');

        var el = document.querySelector('img');
        var img = document.createElement('img');
      
        el.parentNode.replaceChild(img, el);
    }
}
