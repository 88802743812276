import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { Globals, ProductItem, AddressData } from '../../globals'
import { DataService } from '../../data.service';
import { EventEmitterService } from '../../event-emitter.service';

declare interface CartData {
    items: ProductItem[];
}

declare var Stripe: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})

export class CheckoutComponent implements OnInit {
    state: any = {};
    stripe: any;
    cartData: CartData;
    sameShippingAndBilling: boolean = true;
    billingAddress: AddressData = new AddressData();
    shippingAddress: AddressData = new AddressData();
    taxes: number = 0;
    shippingCost: number = 5;

    closeResult: string;
    uploadModal: any;
    emailAddress: string; 
    loginPassword: string;
    invalidLogin: boolean = false;
    registerFailed: boolean = false; 
    loggedIn: boolean = false;
    itemCartCount: number = 0;

    development = process.env.NODE_ENV !== 'production';

    constructor(public globals: Globals, private modalService: NgbModal, private router: Router, private eventEmitter: EventEmitterService,
        private dataService: DataService) {
        this.handleSubmit = this.handleSubmit.bind(this);
        //get cart data
        this.cartData = this.getCartJSON();
        if(this.getCartItemCount()==0){
            this.router.navigate(['/cart/']); 
        }
        console.log(process.env.NODE_ENV);
        // if(this.development) {
        //     this.shippingAddress.name = "Test name";
        //     this.shippingAddress.city = "San Diego";
        //     this.shippingAddress.state = "CA";
        //     this.shippingAddress.zip = "92127";
        //     this.shippingAddress.streetAddress1 = "123 Main St.";
        //     this.emailAddress = "test@gmail.com";
        // }
    }

    ngOnInit() {
        // Your Stripe public key
        const stripe = Stripe('pk_test_X7EIc1a19fKQy5jSXgEUd37M');

        // Create `card` element that will watch for updates
        // and display error messages
        const elements = stripe.elements();
        const card = elements.create('card');
        card.mount('#card-element');
        card.addEventListener('change', event => {
        const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        // Listen for form submission, process the form with Stripe,
        // and get the 
        const paymentForm = document.getElementById('payment-form');
        if(paymentForm != null){
            paymentForm.addEventListener('submit', event => {
                this.addressCheckboxChange(); //updatea address if checkbox is checked
                console.log("shipping:\n" + this.shippingAddress.toString());
                console.log("billing:\n" + this.billingAddress.toString());
                event.preventDefault();
                stripe.createToken(card).then(result => {
                    if (result.error) {
                        console.log('Error creating payment method.');
                        const errorElement = document.getElementById('card-errors');
                        errorElement.textContent = result.error.message;
                        } else {
                        // At this point, you should send the token ID
                        // to your server so it can attach
                        // the payment source to a customer
                        console.log('Token acquired!');
                        console.log(result.token);
                        console.log(result.token.id);
                        //const infoElement = document.getElementById('card-info');
                        //infoElement.textContent = result.token.id;
                        this.dataService.shippingAddress = Object.assign({}, this.shippingAddress);
                        this.dataService.cartData = this.cartData;
                        this.dataService.orderDate = new Date();
                        this.dataService.orderNumber = 1234567890;
                        this.eventEmitter.onShoppingCartEmptyCart();
                        this.router.navigate(['shop/order/', result.token.id]); 
                    }
                });
            });
        }
    }

    addressCheckboxChange(){
        if(!this.sameShippingAndBilling){
            this.billingAddress =  new AddressData();//.clear() 
        }
        else{
            this.billingAddress = Object.assign({}, this.shippingAddress);
            console.log("shipping and billing address are the same");
        }
    }

    calculateShippingCost(){

    }

    calculateTaxes(){

    }

    // submitOrder(){
    //     // Your Stripe public key
    //     const stripe = Stripe('pk_test_X7EIc1a19fKQy5jSXgEUd37M');

    //     // Create `card` element that will watch for updates
    //     // and display error messages
    //     const elements = stripe.elements();
    //     const card = elements.create('card');
    //     card.mount('#card-element');
    //     card.addEventListener('change', event => {
    //     const displayError = document.getElementById('card-errors');
    //         if (event.error) {
    //             displayError.textContent = event.error.message;
    //         } else {
    //             displayError.textContent = '';
    //         }
    //     });

    //     stripe.createToken(card).then(result => {
    //         if (result.error) {
    //         console.log(this.shippingAddress);
    //         console.log(this.billingAddress);
    //         console.log('Error creating payment method.');
    //         const errorElement = document.getElementById('card-errors');
    //         errorElement.textContent = result.error.message;
    //         } else {
    //         // At this point, you should send the token ID
    //         // to your server so it can attach
    //         // the payment source to a customer
    //         console.log('Token acquired!');
    //         console.log(result.token);
    //         console.log(result.token.id);
    //         const infoElement = document.getElementById('card-info');
    //         infoElement.textContent = result.token.id;
    //         }
    //     });

    //     // Listen for form submission, process the form with Stripe,
    //     // and get the 
    //     // const paymentForm = document.getElementById('payment-form');
    //     // if(paymentForm != null){
    //     //     paymentForm.addEventListener('submit', event => {
                
    //     //         event.preventDefault();
    //     //         stripe.createToken(card).then(result => {
    //     //             if (result.error) {
    //     //             console.log(this.shippingAddress);
    //     //             console.log(this.billingAddress);
    //     //             console.log('Error creating payment method.');
    //     //             const errorElement = document.getElementById('card-errors');
    //     //             errorElement.textContent = result.error.message;
    //     //             } else {
    //     //             // At this point, you should send the token ID
    //     //             // to your server so it can attach
    //     //             // the payment source to a customer
    //     //             console.log('Token acquired!');
    //     //             console.log(result.token);
    //     //             console.log(result.token.id);
    //     //             const infoElement = document.getElementById('card-info');
    //     //             infoElement.textContent = result.token.id;
    //     //             }
    //     //         });
    //     //     });
    //     // }
    // }

    getCartItemCount(){
        let cartItemCount = 0;
        let cartDataString = this.getCartString();
        if(cartDataString != null){
            let cartData = JSON.parse(cartDataString);
            cartData.forEach(item => {
                cartItemCount += item.count;
            });
        }
        return cartItemCount;
    }

    open(content) {
        this.uploadModal = this.modalService.open(content);
        this.uploadModal.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    handleSubmit(e){
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }
    
    getTotal(){
        let total = 0;
        total += this.getSubTotal() + this.taxes + this.shippingCost;
        return total;
    }

    getSubTotal(){
        let subTotal = 0;
        let cartDataString = this.getCartString();
        if(cartDataString != null){
            let cartData = JSON.parse(cartDataString);
            cartData.forEach(item => {
                subTotal += Number(item.price)*item.count;
            });
        }
        return subTotal;
    }

    close(){
        this.uploadModal.close();
    }

    storeCartData(cartData){
        localStorage.setItem('cart', JSON.stringify(cartData));
    }

    getCartString(){
        return localStorage.getItem('cart');
    }

    getCartJSON(){
        return JSON.parse(this.getCartString());
    }
}
