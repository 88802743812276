import { Component, OnInit, Input } from '@angular/core';
import {Globals} from '../../globals'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {
    @Input() keepAtBottom: boolean = false;
    data : Date = new Date();

    constructor(public globals: Globals) {
    }

    ngOnInit(){
        if(this.keepAtBottom)
        {
            var footer = document.getElementsByTagName('footer')[0];
            footer.classList.add('bottom-of-page');
        }
    }

    ngOnDestroy(){
        if(this.keepAtBottom){
            var footer = document.getElementsByTagName('footer')[0];
            footer.classList.remove('bottom-of-page');
        }
    }
}
