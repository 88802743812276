import { Component, OnInit, Input } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import {Globals} from '../../globals'
import { HttpHelperService } from '../../httphelper.service';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})

export class LoginRegisterComponent implements OnInit {
    @Input() isRound: boolean = false;
    @Input() image: string;
    state: any = {}

    closeResult: string;
    uploadModal: any;
    emailAddress: string;
    loginPassword: string;
    invalidLogin: boolean = false;
    registerFailed: boolean = false; 
    loggedIn: boolean = false;
    loggingIn: boolean = false;

    constructor(private modalService: NgbModal, public globals: Globals, private httpHelper: HttpHelperService, private http: HttpClient) {
        //get logged status from local storage
        this.loggedIn = this.isLoggedIn();
    }

    open(content, type) {
        this.wipeData();
        if (type === 'sm') {
            //do nothing
        } else {
            this.uploadModal = this.modalService.open(content);
            this.uploadModal.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    login(){
        this.loggingIn = true;
        this.httpHelper.login(this.emailAddress, this.loginPassword).subscribe(
            res => {
                this.loggedIn = this.isLoggedIn();
                this.close();
                console.log(res);
                this.loggingIn = false;
            },
            (err: HttpErrorResponse) => {
                this.loggingIn = false;
                this.invalidLogin = true; //login failed
                this.loginPassword = '';
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
            }
        );
    }

    isLoggedIn(){
        // this.httpHelper.getProductList().subscribe( res => {
        //         console.log(res);
        //     },(err: HttpErrorResponse) => {
        //         console.log(err.name);
        //         console.log(err.message);
        //         console.log(err.status);
        //     }
        // );
        // return false;
        this.httpHelper.verifyToken().subscribe( res => {
                this.loggedIn = true;
                // console.log(res);
                return true;
            },(err: HttpErrorResponse) => {
                // console.log(err.name);
                // console.log(err.message);
                // console.log(err.status);
                return false;
            }
        );
        return false;
    }

    setLoggedIn(loggedIn: boolean){
        localStorage.setItem("loggedIn", loggedIn.toString());
    }

    register(){
        this.httpHelper.signup(this.emailAddress, this.loginPassword).subscribe(res => {
            this.wipeData();
            this.close();
        },
        (err: HttpErrorResponse) => {
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            if(err.status === 403){
                console.log("User account already exist");
                this.registerFailed = true;
            }
        });
    }

    wipeData(){
        this.emailAddress = "";
        this.loginPassword = "";
        this.registerFailed = false;
        this.invalidLogin = false;
        this.registerFailed = false;
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.state = {
            file: null,
            imagePreviewUrl: this.image !== undefined ? this.image:(this.isRound ? './assets/img/placeholder.jpg':'./assets/img/image_placeholder.jpg')
        }
    }

    // handleSubmit(e){
    //     e.preventDefault();
    //     // this.state.file is the file/image uploaded
    //     // in this function you can save the image (this.state.file) on form submit
    //     // you have to call it yourself
    // }

    close(){
        this.uploadModal.close();
    }

    logout(){
        this.setLoggedIn(false);
        localStorage.clear();//("userToken", null);
        this.loggedIn = this.isLoggedIn();
    }

    recoverAccount(){
        this.close();
    }
}
