import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { Globals } from '../../globals'
import { EventEmitterService } from '../../event-emitter.service'
import { IAlert, NotificationComponent } from '../../shared/notification/notification.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    alerts:  Array<IAlert> = [];

    constructor(public location: Location, private element : ElementRef, public globals: Globals, private eventEmitterService: EventEmitterService) {
        this.sidebarVisible = false;
        
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        // this.alerts.push({
        //     id: 1,
        //     type: 'success',
        //     message: 'This is an success alert',
        // }, {
        //     id: 2,
        //     type: 'info',
        //     message: 'This is an info alert',
        // }, {
        //     id: 3,
        //     type: 'warning',
        //     message: 'This is a warning alert',
        //     icon: 'nc-bell-55'
        // }, {
        //     id: 4,
        //     type: 'danger',
        //     message: 'This is a danger alert',
        //     icon: 'nc-bell-55'
        // });
        if (this.eventEmitterService.subsVar2==undefined) {    
            this.eventEmitterService.subsVar2 = this.eventEmitterService.    
            invokeShowNotification.subscribe((alert: IAlert) => {    
              this.alerts.push(alert);    
            });    
          }   
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
}
