import { Input, Component } from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationComponent {
    @Input() public alerts: Array<IAlert> = [];
    private backup: Array<IAlert>;
    private _success = new Subject<string>();

    staticAlertClosed = false;
    successMessage = '';

    // ngOnInit(): void {
    //     setTimeout(() => this.closeAlert(this.alerts[0]), 5000);

    //     this._success.subscribe(message => this.successMessage = message);
    //     this._success.pipe(
    //         debounceTime(5000)
    //     ).subscribe(() => this.successMessage = '');
    // }

    constructor() {
        // this.alerts.push({
        //     id: 1,
        //     type: 'success',
        //     message: 'This is an success alert',
        // });
        // }, {
        //     id: 2,
        //     type: 'info',
        //     message: 'This is an info alert',
        // }, {
        //     id: 3,
        //     type: 'warning',
        //     message: 'This is a warning alert',
        //     icon: 'nc-bell-55'
        // }, {
        //     id: 4,
        //     type: 'danger',
        //     message: 'This is a danger alert',
        //     icon: 'nc-bell-55'
        // });

        //this.backup = this.alerts.map((alert: IAlert) => Object.assign({}, alert));
    }

    public closeAlert(alert: IAlert) {
        const index: number = this.alerts.indexOf(alert);
        this.alerts.splice(index, 1);
    }
}

export interface IAlert {
    id: number;
    type: string;
    message: string;
    icon?: string;
}
