import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { NouisliderModule } from 'ng2-nouislider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AgmCoreModule } from '@agm/core';
import { NgxGalleryModule } from 'ngx-gallery';

import { ImageUploadModule } from '../shared/image-upload/image-upload.module';
// import { NotificationComponent } from '../shared/notification/notification.component';

import { MainComponent } from './main.component';
import { ShoppingCartModule } from '../shared/shopping-cart/shopping-cart.module';
import { FooterModule } from '../shared/footer/footer.module';
import { EcommerceModule } from '../ecommerce/ecommerce.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        TagInputModule,
        NouisliderModule,
        JwBootstrapSwitchNg2Module,
        NgxGalleryModule,
        AngularMultiSelectModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBr-tgUtpm8cyjYVQDrjs8YpZH7zBNWPuY'
        }),
        ImageUploadModule,
        ShoppingCartModule,
        FooterModule,
        EcommerceModule
    ],
    declarations: [
        MainComponent,
        // NotificationComponent
    ]
})
export class MainModule { }
