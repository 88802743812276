import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import * as Rellax from 'rellax';
import { Router, NavigationEnd } from '@angular/router';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

import {Globals} from '../globals'

//t = current time
//b = start value
//c = change in value
//d = duration
var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  data : Date = new Date();

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(router: Router, public globals: Globals) {
    router.events.subscribe(s => {
        if (s instanceof NavigationEnd) {
            const tree = router.parseUrl(router.url);
            if (tree.fragment) {
                const element = document.querySelector("#" + tree.fragment);
                if (element) { element.scrollIntoView(); }
            }
        }
    });
  }

  @HostListener('window:scroll', ['$event'])

  ngOnInit() {
    //var rellaxHeader = new Rellax('.rellax-header');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');

    //image gallery
    this.galleryOptions = [
        {
            width: '600px',
            height: '400px',
            thumbnailsColumns: 4,
            imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 800
        {
            "previewFullscreen": true,
            "previewKeyboardNavigation": true,
            "previewCloseOnClick": true,
            "previewCloseOnEsc": true,
            "previewZoom": true,
            "imageSwipe": true,
            "thumbnailsSwipe": true,
            "previewSwipe": true,
            "imageArrowsAutoHide": true,
            "thumbnailsArrowsAutoHide": true,
            "imageAnimation": "slide"
        },
        {
            breakpoint: 800,
            width: '100%',
            height: '600px',
            imagePercent: 80,
            thumbnailsPercent: 20,
            thumbnailsMargin: 20,
            thumbnailMargin: 20
        },
        // max-width 400
        {
            breakpoint: 400,
            preview: false
        }
    ];



    this.galleryImages = [
        {
            small: './assets/img/gallery/outfit-gucci.jpg',
            medium: './assets/img/gallery/outfit-gucci.jpg',
            big: './assets/img/gallery/outfit-gucci.jpg'
        },
        {
            small: './assets/img/gallery/outfit-paul-smith.jpg',
            medium: './assets/img/gallery/outfit-paul-smith.jpg',
            big: './assets/img/gallery/outfit-paul-smith.jpg'
        },
        {
            small: './assets/img/gallery/outfit-maison-margiela.jpg',
            medium: './assets/img/gallery/outfit-maison-margiela.jpg',
            big: './assets/img/gallery/outfit-maison-margiela.jpg'
        },
        {
            small: './assets/img/gallery/outfit-burberry.jpg',
            medium: './assets/img/gallery/outfit-burberry.jpg',
            big: './assets/img/gallery/outfit-burberry.jpg'
        },
        {
            small: './assets/img/gallery/ressence.jpg',
            medium: './assets/img/gallery/ressence.jpg',
            big: './assets/img/gallery/ressence.jpg'
        },
        {
            small: './assets/img/gallery/gucci-sun.jpg',
            medium: './assets/img/gallery/gucci-sun.jpg',
            big: './assets/img/gallery/gucci-sun.jpg'
        },
        {
            small: './assets/img/gallery/bottega.jpg',
            medium: './assets/img/gallery/bottega.jpg',
            big: './assets/img/gallery/bottega.jpg'
        },
        {
            small: './assets/img/gallery/bracelet.jpg',
            medium: './assets/img/gallery/bracelet.jpg',
            big: './assets/img/gallery/bracelet.jpg'
        }
    ];
}

  ngOnDestroy() {
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }

  smoothScroll(target) {
        var targetScroll = document.getElementById(target);
        this.scrollTo(document.documentElement, targetScroll.offsetTop, 1250);
  }

  scrollTo(element, to, duration) {
      var start = element.scrollTop,
          change = to - start,
          currentTime = 0,
          increment = 20;

      var animateScroll = function(){
          currentTime += increment;
          var val = easeInOutQuad(currentTime, start, change, duration);
          element.scrollTop = val;
          if(currentTime < duration) {
              setTimeout(animateScroll, increment);
          }
      };
      animateScroll();
  }

  ngAfterViewInit(){
    var rellaxHeader = new Rellax('.rellax-header');
      //this.updateNavigation();
  }
}
