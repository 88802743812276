import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
import { ProductItem, AddressData } from './globals';
import { IAlert } from './shared/notification/notification.component';

@Injectable({    
  providedIn: 'root'    
})    

export class EventEmitterService {    
  invokeAddToCartFunction = new EventEmitter(); 
  invokeClearCartFunction = new EventEmitter();   
  invokeShowNotification = new EventEmitter();  
  invokeOrderConfirmationComplete = new EventEmitter();
  subsVar: Subscription;    
  subsVar2: Subscription;    
  subsVar3: Subscription;    
    
  constructor() { }    
    
  onShoppingCartAddToCart(product: ProductItem) {    
    this.invokeAddToCartFunction.emit(product);    
  }   

  onShoppingCartEmptyCart(){
    this.invokeClearCartFunction.emit();
  }  

  onShowNotification(alert: IAlert) {    
    this.invokeShowNotification.emit(alert);    
  }
  
  onOrderConfirmationComplete(shipAddress: AddressData) {    
    this.invokeOrderConfirmationComplete.emit(shipAddress);    
  }  
}   