import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../footer/footer.module';
import { OrderConfirmationComponent } from './orderConfirmation/orderConfirmation.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    FooterModule
  ],
  declarations: [
    CheckoutComponent,
    OrderConfirmationComponent
  ],
  exports: [ CheckoutComponent ]
})
export class CheckoutModule { }
