import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { SectionsModule } from './sections/sections.module';
import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
// import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { PresentationComponent } from './presentation/presentation.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { PresentationModule } from './presentation/presentation.module';

import { MainModule } from './main/main.module';

import { LoginRegisterModule } from './shared/login-register/login-register.module';
import { ShoppingCartModule } from './shared/shopping-cart/shopping-cart.module';
import { FooterModule } from './shared/footer/footer.module';
import { EcommerceModule } from './ecommerce/ecommerce.module';
import { CheckoutModule } from './shared/checkout/checkout.module';

import { NotificationComponent } from './shared/notification/notification.component';

import { Globals } from './globals';
import { DataService } from './data.service';
import { HttpHelperService } from './httphelper.service';
import { EventEmitterService } from './event-emitter.service';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        NotificationComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        PresentationModule,
        SectionsModule,
        ComponentsModule,
        ExamplesModule,
        MainModule,
        LoginRegisterModule,
        HttpClientModule,
        ShoppingCartModule,
        FooterModule,
        EcommerceModule,
        CheckoutModule
    ],
    providers: [ 
        Globals, 
        HttpHelperService, 
        CookieService, 
        EventEmitterService,
        DataService 
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
